<template>
  <div>
    <b-card>
      <v-table
        path="/cars/plans/journal"
        sort="-created_at"
        :fields="tableFields"
        :filter="tableFilters"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import VTable from '@/views/components/vtable'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  components: {
    BCard,
    VTable,
  },
  data() {
    return {
      tableFields: [
        {
          key: 'created_at',
          sortable: true,
          label: 'Дата',
          formatter(val) {
            return moment(val).format('DD.MM.YY HH:mm:ss')
          },
        },
        {
          key: 'user',
          label: 'Пользователь',
        },
        {
          key: 'action',
          label: 'Действие',
        },
        {
          key: 'number',
          label: 'Авто',
        },
        {
          key: 'attributes',
          label: 'План',
          formatter(val) {
            return `${val.date} ${val.is_day ? 'День' : 'Ночь'}`
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      search: state => state.app.globalSearch,
    }),
    tableFilters() {
      return {
        search: this.search,
      }
    },
  },
}
</script>

<style scoped>

</style>
